<template>
  <div class="fluid" id="categories">
    <b-card>
      <div class="d-flex justify-content-end align-items-center mx-1">
        <div>
          <b-link
            to="create-job-templete"
            v-if="$can('create', 'Category')"
            class="btn add-btn ml-auto mt-1"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add Templete </span>
              <span>
                <img
                  src="@/assets/images/icons/add-icon.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-link>
        </div>
      </div>
    </b-card>
    <div class="fluid">
      <templete-list-component :jobTemplete="templetes" />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";

import { toast } from "@/utils/toast";
import vSelect from "vue-select";
import templeteListComponent from "./components/templetLIstComponent.vue";
export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
    templeteListComponent,
  },

  data() {
    return {
      homeLink: false,
      templetes: [],

      categories: [],
      nameState: null,
      category: {
        name: "",
      },
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getJobTemplete();
  },
  methods: {
    getJobTemplete() {
      if (this.SelectedCategory) {
        // form.category
        this.category_id = this.SelectedCategory.id;
      }
      this.$store
        .dispatch("job/getJobTemplete")
        .then((response) => {
          this.templetes = response.data;
        })
        .catch((error) => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Creating Job");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
